import Phaser from "phaser";

let player: any;
let loading: boolean;
let prevNum: string;
let id: number;

export default class HumanScene extends Phaser.Scene {
  constructor(idid: number) {
    console.log("adding scene");
    super("human");
    loading = true;
    prevNum = "";
    id = 1;
    console.log(idid);
  }

  init() {
    console.log("init");
  }

  preload() {
    console.log("preload");
    this.load.spritesheet(
      "human",
      `https://metadata.humansofnft.com/sprites/${id}_512x512.png`,
      {
        frameWidth: 128,
        frameHeight: 128,
      }
    );
  }

  resize() {
    console.log("resize");
    this.cameras.resize(window.innerWidth, window.innerHeight);
  }

  restart(newId: number) {
    id = newId;
    loading = true;
    this.physics.disableUpdate();
    this.textures.remove("human");
    this.scene.restart();
  }

  create() {
    player = this.physics.add
      .sprite(0, 0, "human")
      .setBounce(0.2)
      .setCollideWorldBounds(true)
      .setGravity(0, 0);

    this.anims.remove("turn");
    this.anims.remove("right");
    this.anims.remove("up");
    this.anims.remove("left");
    this.anims.remove("down");

    setTimeout(() => {
      this.anims.create({
        key: "turn",
        frames: [{ key: "human", frame: 13 }],
        frameRate: 20,
      });

      this.anims.create({
        key: "right",
        frames: this.anims.generateFrameNumbers("human", { start: 0, end: 3 }),
        frameRate: 8,
      });

      this.anims.create({
        key: "up",
        frames: this.anims.generateFrameNumbers("human", { start: 4, end: 7 }),
        frameRate: 8,
      });

      this.anims.create({
        key: "left",
        frames: this.anims.generateFrameNumbers("human", { start: 8, end: 11 }),
        frameRate: 8,
      });

      this.anims.create({
        key: "down",
        frames: this.anims.generateFrameNumbers("human", {
          start: 12,
          end: 15,
        }),
        frameRate: 8,
      });
      loading = false;
    }, 300);
  }

  update() {
    if (loading) return;
    const cursors = this.input.keyboard.createCursorKeys();
    player.setVelocityX(0);
    player.setVelocityY(0);

    if (cursors.left.isDown) {
      player.setVelocityX(-200);
      player.anims.play("left", true);
    } else if (cursors.right.isDown) {
      player.setVelocityX(200);
      player.anims.play("right", true);
    } else if (cursors.up.isDown) {
      player.anims.play("up", true);
      player.setVelocityY(-200);
    } else if (cursors.down.isDown) {
      player.anims.play("down", true);
      player.setVelocityY(200);
    } else {
      player.setVelocityX(0);
      player.setVelocityY(0);
      player.anims.play("turn");
    }
  }
}
