import { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import phaserGame from "./PhaserGame";
import HelloWorldScene from "./scenes/HelloWorldScene";
import HumanScene from "./scenes/HumanScene";
import useWindowWidth from "./hooks/useWindowWidth";

import toast, { Toaster } from "react-hot-toast";

// const handleClick = () => {
//   const human = phaserGame.scene.keys.human as HumanScene;
//   // human.create();
//   // human.preload();
//   // human.create();
//   const num = Math.floor(270 + Math.random() * 1200);
//   // human.reload(num.toString());
//   human.restart();
// };

const missingIds = [
  200, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243,
  244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258,
  259, 260, 261, 262, 263, 264, 357, 403, 499, 519, 523, 599, 649, 667, 698,
  734, 739, 773, 788, 796, 809, 812, 857, 941, 1010, 1014, 1120, 1143, 1156,
  1181, 1222, 1243, 1254, 1296, 1324, 1336, 1343, 1442, 1444, 1475, 1490,
];

let loadTimeout: any = null;

function App() {
  const [id, setId] = useState(1);
  const [loading, setLoading] = useState(false);
  const human = phaserGame.scene.keys.human as HumanScene;
  const width = useWindowWidth();

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    setLoading(true);
    loadTimeout = setTimeout(() => {
      setLoading(false);
      clearTimeout(loadTimeout);
      loadTimeout = null;
    }, 1000);
  };

  const getRandomId: any = () => {
    const num = Math.ceil(Math.random() * 1499);
    if (missingIds.includes(num)) return getRandomId();
    else return num;
  };

  const handleRandom = () => {
    const rand = getRandomId();
    load();
    setId(rand);
    human.restart(rand);
  };

  const handleClick = () => {
    if (missingIds.includes(id)) {
      toast.error(
        "We're sorry. The spritesheet for this ID is currently being updated. Please check back soon.",
        {
          position: "top-right",
          duration: 10000,
        }
      );
      return;
    }
    load();
    human.restart(id);
  };

  const updateId = (e: any) => {
    const newID = parseInt(e.target.value);

    if (newID < 0 || newID > 1500) return;
    setId(newID);
  };

  return (
    <div className="App">
      {loading && (
        <div className="flex items-center justify-center text-white w-full h-full absolute top-0 left-0">
          Loading...
        </div>
      )}

      <header className="App-header">
        <div id="phaser">
          <div className="flex flex-col absolute left-0 bottom-0 p-5">
            <div>
              <button className="button text-sm mb-5" onClick={handleRandom}>
                Load Random
              </button>
            </div>
            <div className="mb-5">
              <button className="button text-sm" onClick={handleClick}>
                Load Selected ID
              </button>
            </div>
            <div className="flex flex-row text-sm">
              <div className="flex justify-center items-center mr-5 h-100">
                ID:
              </div>
              <input
                className="text-black text-xs w-full h-[40px] p-2 rounded"
                type="number"
                value={id}
                onChange={updateId}
                min={1}
                max={1500}
              />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
